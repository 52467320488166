import { mergeClasses } from '@expo/styleguide';
import { TerminalSquareIcon } from '@expo/styleguide-icons/outline/TerminalSquareIcon';

import { CODE } from '~/ui/components/text';

import { Snippet } from '../Snippet';
import { SnippetContent } from '../SnippetContent';
import { SnippetHeader } from '../SnippetHeader';
import { CopyAction } from '../actions/CopyAction';

type TerminalProps = {
  cmd: string[];
  cmdCopy?: string;
  hideOverflow?: boolean;
  title?: string;
  className?: string;
};

export const Terminal = ({
  cmd,
  cmdCopy,
  hideOverflow,
  className,
  title = 'Terminal',
}: TerminalProps) => (
  <Snippet
    className={mergeClasses('rounded-md border border-transparent dark:border-default', className)}>
    <SnippetHeader alwaysDark title={title} Icon={TerminalSquareIcon}>
      {renderCopyButton({ cmd, cmdCopy })}
    </SnippetHeader>
    <SnippetContent alwaysDark hideOverflow={hideOverflow} className="flex flex-col">
      {cmd.map(cmdMapper)}
    </SnippetContent>
  </Snippet>
);

/**
 * This method attempts to naively generate the basic cmdCopy from the given cmd list.
 * Currently, the implementation is simple, but we can add multiline support in the future.
 */
function getDefaultCmdCopy(cmd: TerminalProps['cmd']) {
  const validLines = cmd.filter((line) => !line.startsWith('#') && line !== '');
  if (validLines.length === 1) {
    return validLines[0].startsWith('$') ? validLines[0].slice(2) : validLines[0];
  }
  return undefined;
}

function renderCopyButton({ cmd, cmdCopy }: TerminalProps) {
  const copyText = cmdCopy ?? getDefaultCmdCopy(cmd);
  return copyText && <CopyAction alwaysDark text={copyText} />;
}

/**
 * Map all provided lines and render the correct component, logic as follows:
 * - Render newlines for empty strings
 * - Render a line with `#` prefix as comment
 * - Render a line with `$` prefix, as command
 * - Render a line without `$` prefix as output text
 */
function cmdMapper(line: string, index: number) {
  const key = `line-${index}`;

  if (line.trim() === '') {
    return <br key={key} className="select-none" />;
  }

  if (line.startsWith('#')) {
    return (
      <CODE
        key={key}
        className="min-h-[22px] select-none whitespace-pre-line border-none bg-transparent px-0 text-palette-gray10">
        {line}
      </CODE>
    );
  }

  if (line.startsWith('$')) {
    return (
      <div key={key} className="flex items-start">
        <CODE className="select-none whitespace-pre-line border-none bg-transparent px-0 text-quaternary">
          -&nbsp;
        </CODE>
        <CODE className="whitespace-pre-line border-none bg-transparent px-0 text-default">
          {line.substring(1).trim()}
        </CODE>
      </div>
    );
  }

  return (
    <CODE
      key={key}
      className="min-h-[22px] whitespace-pre-line border-none bg-transparent px-0 text-default">
      {line}
    </CODE>
  );
}
