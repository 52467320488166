import { mergeClasses } from '@expo/styleguide';
import { type MDXComponents } from 'mdx/types';
import { type AnchorHTMLAttributes, HTMLAttributes } from 'react';

import { A, H2, H3, H4, H5, CODE } from '~/ui/components/text';

export function useMDXComponents(components: MDXComponents): MDXComponents {
  return {
    a: ({ href, className, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) => {
      return (
        <A
          href={href}
          className={className}
          isStyled={!className?.includes('not-prose')}
          openInNewTab={href?.includes('://') ?? false}
          {...rest}
        />
      );
    },
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    code: ({ className, ...rest }: HTMLAttributes<HTMLElement>) => {
      if (className) {
        return <>{rest.children}</>;
      }
      return <CODE className={mergeClasses('inline px-0', className)} {...rest} />;
    },
    ...components,
  };
}
