import { ClipboardIcon } from '@expo/styleguide-icons/outline/ClipboardIcon';
import copy from 'clipboard-copy';
import { useState } from 'react';

import { SnippetAction, SnippetActionProps } from '../SnippetAction';

type CopyActionProps = SnippetActionProps & {
  text: string | (() => Promise<string>);
};

export function CopyAction({ text, ...rest }: CopyActionProps) {
  const [copyDone, setCopyDone] = useState(false);

  const onCopyClickAsync = async () => {
    if (typeof text === 'function') {
      text = await text();
    }
    await copy(text);
    setCopyDone(true);
    setTimeout(() => {
      setCopyDone(false);
    }, 1500);
  };

  return (
    <SnippetAction
      leftSlot={<ClipboardIcon className="icon-sm text-icon-default" />}
      onClick={onCopyClickAsync}
      disabled={copyDone}
      {...rest}>
      {copyDone ? 'Copied!' : 'Copy'}
    </SnippetAction>
  );
}
