import { ArrowRightIcon } from '@expo/styleguide-icons/outline/ArrowRightIcon';
import { useRouter } from 'next/compat/router';
import { type PropsWithChildren } from 'react';

import { Button } from '~/ui/components/Button';

type Props = PropsWithChildren<{
  slug: string;
}>;

export function FullPostContent({ children, slug }: Props) {
  const router = useRouter();

  if (router?.pathname === '/changelog') {
    return (
      <Button href={`/changelog${slug}`} theme="secondary" rightSlot={<ArrowRightIcon />}>
        Read more
      </Button>
    );
  }

  return children;
}
