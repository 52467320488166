import { mergeClasses } from '@expo/styleguide';
import { forwardRef, PropsWithChildren } from 'react';

export type SnippetContentProps = PropsWithChildren<{
  alwaysDark?: boolean;
  hideOverflow?: boolean;
  className?: string;
  wordWrap?: boolean;
}>;

export const SnippetContent = forwardRef<HTMLDivElement, SnippetContentProps>(
  (
    {
      children,
      className,
      alwaysDark = false,
      hideOverflow = false,
      wordWrap = true,
    }: SnippetContentProps,
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={mergeClasses(
          'overflow-x-auto rounded-b-md border border-default bg-subtle p-4 text-default',
          'prose-code:px-0 prose-code:leading-[19px]',
          alwaysDark && 'dark-theme whitespace-nowrap border-transparent bg-palette-black',
          hideOverflow && 'overflow-hidden prose-code:!whitespace-nowrap',
          wordWrap && 'prose-code:!whitespace-break-spaces',
          className
        )}>
        {children}
      </div>
    );
  }
);

SnippetContent.displayName = 'SnippetContent';
