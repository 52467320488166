import { forwardRef, ImgHTMLAttributes } from 'react';

import { getAssetUrl } from '~/common/utilities';

/**
 * If your image isn't currently optimized for WebP and AVIF,
 * run `npx sharp-cli --format <avif/webp> --lossless --input <filename> --output <directory or new filename>`
 * and it will create optimized versions of your image.
 *
 * https://github.com/vseventer/sharp-cli
 */

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
};

export const OptimizedImage = forwardRef<HTMLImageElement, Props>(function Image(
  { src, alt, ...rest },
  ref
) {
  // return a normal image if a gif/svg is passed in
  if (/\?.(gif|svg)$/.test(src)) {
    return <img {...rest} ref={ref} src={getAssetUrl(src)} alt={alt} />;
  }

  const baseSrc = src.replace(/\.(jpe?g|png|bmp)$/i, '');

  const webpSrc = `${baseSrc}.webp`;
  const avifSrc = `${baseSrc}.avif`;

  if (typeof window === 'undefined' && process.env.NODE_ENV === 'development') {
    const fs = require('node:fs');
    const path = require('node:path');

    const getAbsolutePath = (url: string) => path.join(process.cwd(), 'public', url);

    if (!fs.existsSync(getAbsolutePath(src))) {
      throw new Error(`${src} does not exist.`);
    }

    if (!fs.existsSync(getAbsolutePath(webpSrc))) {
      throw new Error(
        `${webpSrc} does not exist. You can generate it with Sharp CLI: npx sharp-cli --format webp --lossless --input <filename> --output <directory or new filename>.`
      );
    }

    if (!fs.existsSync(getAbsolutePath(avifSrc))) {
      throw new Error(
        `${avifSrc} does not exist. You can generate it with Sharp CLI: npx sharp-cli --format avif --lossless --input <filename> --output <directory or new filename>`
      );
    }
  }

  return (
    <picture>
      <source srcSet={getAssetUrl(avifSrc)} type="image/avif" />
      <source srcSet={getAssetUrl(webpSrc)} type="image/webp" />
      <img {...rest} ref={ref} src={getAssetUrl(src)} alt={alt} />
    </picture>
  );
});
