import { type ButtonProps, mergeClasses } from '@expo/styleguide';

import { Button } from '~/ui/components/Button';
import { FOOTNOTE } from '~/ui/components/text';

export type SnippetActionProps = ButtonProps & {
  alwaysDark?: boolean;
};

export function SnippetAction({
  children,
  leftSlot,
  rightSlot,
  className,
  alwaysDark = false,
  ...rest
}: SnippetActionProps) {
  return (
    <Button
      size="xs"
      theme="quaternary"
      leftSlot={leftSlot}
      rightSlot={rightSlot}
      className={mergeClasses(
        'focus-visible:-outline-offset-2',
        alwaysDark &&
          'dark-theme border-transparent bg-transparent hocus:border-palette-gray9 hocus:bg-palette-gray5 hocus:shadow-xs',
        !alwaysDark &&
          'h-10 rounded-none border-0 border-l border-l-default px-4 leading-10 hocus:bg-subtle hocus:shadow-none',
        className
      )}
      {...rest}>
      {children && (
        <FOOTNOTE className={mergeClasses(alwaysDark && 'text-palette-white')}>{children}</FOOTNOTE>
      )}
    </Button>
  );
}
