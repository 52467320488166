import { mergeClasses } from '@expo/styleguide';
import { ArrowLeftIcon } from '@expo/styleguide-icons/outline/ArrowLeftIcon';
import { format } from 'date-fns/format';
import { type ReactElement } from 'react';

import { dateFnsFormats } from '~/common/constants';
import { PostMetadata } from '~/common/metatags';
import { getAssetUrl } from '~/common/utilities';
import { Button } from '~/ui/components/Button';
import Document from '~/ui/components/Document';
import { LinkBase } from '~/ui/components/LinkBase';
import { StructuredData } from '~/ui/components/StructuredData';
import { A, CALLOUT, H1, HEADLINE } from '~/ui/components/text';

import { CreditsPerson } from './Credits/CreditsPerson';

type Props = {
  meta: PostMetadata;
  content: ReactElement;
  presentation?: 'default' | 'list-item' | 'highlighted-item';
};

export function PostLayout({ content, meta, presentation = 'default' }: Props) {
  const { title, date, author, slug, image, description } = meta;
  const isDetailPage = presentation === 'default';

  if (presentation === 'list-item') {
    return (
      <LinkBase
        href={`/changelog${slug}`}
        className="w-full rounded-md px-3 py-2 transition-colors hocus:bg-element">
        <div className="flex flex-col gap-1">
          <HEADLINE className="m-0">{title}</HEADLINE>
          <div className="flex items-center gap-1.5">
            <CALLOUT theme="secondary" className="m-0">
              {format(new Date(date), dateFnsFormats.date)} by
            </CALLOUT>
            <CreditsPerson name={author} />
          </div>
        </div>
      </LinkBase>
    );
  }

  const postContent = (
    <div
      className={mergeClasses(
        'mx-auto w-full max-w-screen-lg',
        'max-md-gutters:max-w-full',
        isDetailPage && 'min-h-screen px-4 pb-20 pt-10'
      )}>
      {isDetailPage && (
        <Button theme="secondary" href="/changelog" className="mb-10" leftSlot={<ArrowLeftIcon />}>
          All Posts
        </Button>
      )}
      <div className="mb-5 flex flex-col gap-3">
        {isDetailPage ? (
          <H1 className="m-0 font-bold">{title}</H1>
        ) : (
          <A href={`/changelog${slug}`}>
            <H1 className="m-0 font-bold">{title}</H1>
          </A>
        )}
        <div className="flex items-center gap-1.5">
          <CALLOUT theme="secondary" className="m-0">
            {format(new Date(date), dateFnsFormats.date)} by
          </CALLOUT>
          <CreditsPerson name={author} />
        </div>
      </div>
      <div className="prose max-w-full dark:prose-invert">{content}</div>
    </div>
  );

  if (presentation === 'highlighted-item') {
    return postContent;
  }

  return (
    <Document
      meta={{
        name: isDetailPage ? 'changelogDetail' : 'changelog',
        changelogMetadata: meta,
      }}
      className="min-h-0 dark:bg-screen">
      {isDetailPage && (
        <StructuredData
          data={{
            '@context': 'https://schema.org',
            '@type': 'Article',
            headline: title,
            image: image ? [getAssetUrl(image)] : undefined,
            datePublished: date,
            description,
            author: [
              {
                '@type': 'Person',
                name: author,
              },
            ],
          }}
        />
      )}
      {postContent}
    </Document>
  );
}
