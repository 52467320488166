import type { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  figure: ReactNode;
}>;

export function ContentSpotlight({ children, figure }: Props) {
  return (
    <figure className="[&>video]:!mb-0">
      {figure}
      <figcaption className="text-center">{children}</figcaption>
    </figure>
  );
}
