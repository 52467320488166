import { teamEmployees, teamSoftwareMansion } from '~/common/static-data';
import { OptimizedImage } from '~/ui/components/OptimizedImage';
import { CALLOUT } from '~/ui/components/text';

type Props = {
  name: string;
  src?: string;
  credit?: string;
};

export function CreditsPerson({ name, src, credit }: Props) {
  const employee = [...teamEmployees, ...teamSoftwareMansion].find(
    (employee) => employee.name === name
  );
  const avatar = src ?? employee?.src;

  return (
    <div className="flex items-center gap-1.5">
      {avatar && (
        <div className="shrink-0">
          <OptimizedImage
            src={avatar}
            height={26}
            width={26}
            alt={`Avatar of ${name}`}
            className="m-0 size-[26px] max-h-[26px] max-w-[26px] rounded-full object-cover"
          />
        </div>
      )}
      <CALLOUT className="m-0">
        <span className="font-medium">{name}</span> {credit}
      </CALLOUT>
    </div>
  );
}
